.text-center {
  text-align: center;
}

.mx-auto {
  margin: 0 auto;
}

.hstack {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hstack > *:not(:last-child) {
  margin-right: 0.5em;
}
